import { AppStackParamList } from "./../../types";
/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */
import { LinkingOptions } from "@react-navigation/native";

const linking: LinkingOptions<AppStackParamList> = {
  enabled: true,
  prefixes: ["amana://"],
  config: {
    screens: {
      RootApp: {
        initialRouteName: "Root",
        path: "",
        screens: {
          Root: {
            path: "",
            screens: {
              Foryou: {
                path: "foryou",
              },
              Watchlist: {
                path: "watchlist/:screen?/:key?/:symbol?",
                parse: {
                  screen: (screen: string) => +screen || 0,
                  key: (key: string) => +key || 0,
                  symbol: (key: string) => key,
                },
              },
              Portfolio: {
                path: "portfolio/:screen?/:key?",
                parse: {
                  screen: (screen: string) => +screen,
                  key: (key: string) => +key,
                },
              },
              Plus: {
                path: "education/:screen?",
                parse: {
                  screen: (screen: string) => +screen,
                  key: (key: string) => +key,
                },
              },
              Profile: {
                path: "profile",
                screens: {
                  CloseYourAccount: {
                    path: "CloseYourAccount",
                  },
                },
              },
            },
          },
          InvestPlans: {
            path: "invest-plans",
            screens: {
              InvestHome: {
                path: "home",
              },
              InvestmentDetails: {
                path: "details/:id",
              },
              PopularPlans: {
                path: "popular-plans/:id",
              },
              CreateEditPlan: {
                path: "create-edit-plan/:?planId",
              },
              FundPlan: {
                path: "fund-plan/:planId",
              },
              Terms: {
                path: "terms",
              },
            },
          },
          PaymentMethod: {
            path: "paymentmethod",
          },
          DepositWithCard: {
            path: "depositwithcard",
          },
          DepositWithBankTransfer: {
            path: "depositwithbanktransfer",
          },
          DepositWithWallet: {
            path: "depositwithwallet",
          },
          DepositWithPraxis: {
            path: "depositwithpraxis",
          },
          DepositWithWhish: {
            path: "depositwithwhish",
          },
          DepositWithGateToPay: {
            path: "depositwithgatetopay",
          },
          WireTransfer: {
            path: "wiretransfer",
          },
          Withdraw: {
            path: "withdraw",
          },
          Support: {
            path: "support",
          },
          Settings: {
            path: "settings",
          },
          OnboardingFlows: {
            path: "onboarding",
          },
          AssetInfo: {
            path: "symbol/:symbol",
          },
          WatchlistChart: {
            path: "assetInfo/:list",
            parse: {
              list: (listSymbol: string) => [listSymbol],
              index: () => 0,
            },
          },
          NewOrder: {
            path: "neworder/:symbol",
          },
          DeepLinkVideoContainer: {
            path: "player/:id",
          },
          Trade: {
            path: "trade",
          },
          RequestHighLeverage: {
            path: "highleverage",
          },
        },
      },
      AuthApp: {
        path: "public",
        screens: {
          LoginScreen: {
            path: "login",
          },
          SignUpNavigation: {
            path: "signup/:mt?",
          },
        },
      },
    },
  },
};

export default linking;
