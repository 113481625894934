import { navigateAndReset } from "@/helpers/navigation";
import {
  accountStore,
  alertStore,
  appUiStore,
  getFreshToken,
  queryClient,
  symbolStore,
} from "@/Lib";
import { storage } from "@/Lib/storage";
import { settingsStore } from "@/Lib/stores/SettingsStore";
import { captureException } from "@sentry/react-native";
import { runInAction } from "mobx";

const getHistory = (
  variant: "success" | "failed" | "welcome",
  link?: string
) => ({
  md: [
    {
      name: "Root",
      params: {
        screen: "Profile",
        params: {
          screen: "DepositWithCard",
        },
      },
    },
    {
      name: "Root",
      params: {
        screen: "Profile",
        params: {
          screen: "ProfileWarningScreen",
          params: { variant, link },
        },
      },
    },
  ],
  mobile: [
    {
      name: "Root",
      params: { screen: "Profile" },
    },
    { name: "PaymentMethod" },
    {
      name: "ProfileWarningScreen",
      params: { variant, link },
    },
  ],
});

export const handlePushNotification = async (notification: any) => {
  const notificationData = notification?.data;
  const md = appUiStore.layout.width > 768;
  switch (notificationData?.cmd) {
    case "uaepass_status_changed":
      runInAction(() => {
        settingsStore.gender = notificationData?.gender;
        settingsStore.dob = notificationData?.dob;
        settingsStore.datasharing = true;
      });
      navigateAndReset([
        {
          name: "Root",
        },
        { name: "UaepassDocSharingStatus", params: { variant: "success" } },
      ]);
      break;
    case "uaepass_failed":
      navigateAndReset([
        {
          name: "Root",
        },
        { name: "UaepassDocSharingStatus", params: { variant: "failed" } },
      ]);
      break;
    case "status_changed":
      accountStore
        .refresh()
        .then(() => {
          const level1Approved =
            accountStore.AccountState?.name === "LEVEL-1 Approved";
          if (level1Approved && accountStore.isMT) {
            settingsStore.setMtWelcome(false);
            navigateAndReset(
              getHistory("welcome", "MTAccountInfo")[md ? "md" : "mobile"]
            );
          }
        })
        .catch(captureException);
      break;
    case "payment_status_changed":
      await accountStore.getTradingAccounts().then((res) => {
        if (appUiStore.jwt?.mt?.length !== res?.length) {
          const refreshToken = storage.getString("refresh_token");

          return getFreshToken(refreshToken);
        }
      });

      symbolStore.clear();
      symbolStore.init(appUiStore.ActiveAccount?.group);

      queryClient.invalidateQueries(["notifications", appUiStore.accountId]);
      queryClient.invalidateQueries(["netdeposits"]);

      if (notification?.body) {
        appUiStore.setStatusModal({
          msg: notification.body,
        });
      }
      navigateAndReset([
        { name: "Root", params: { screen: "Profile" } },
        {
          name: "StatusModal",
          params: { variant: "success", name: "deposit" },
        },
      ]);
      break;
    case "deposit_failed":
      navigateAndReset([
        { name: "Root", params: { screen: "Profile" } },
        { name: "StatusModal", params: { variant: "failed", name: "deposit" } },
      ]);
      break;
    case "refresh_alerts":
      alertStore.setup();
      break;
    default:
      break;
  }
};
